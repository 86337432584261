<template lang="pug">
    form.c-form(@submit.prevent="send" :class="id==='constructor-order' ? 'c-form--constructor-order' : ''")
        .c-form__title(v-html="title")
        .c-form__body
            .c-form__sub-title(v-if="sub_title") {{sub_title}}
            .c-form__row(v-if="id=='constructor'")
              .c-constructor__right
                .c-constructor__right-form
                  .c-constructor__right-pic
                    <img :src="require(`@/assets/img/content/forms/${val_pic}`)" alt="">
                  .c-constructor__right-name {{val_form}}
                .c-constructor__right-body
                  ul.c-constructor__right-char
                    li.c-constructor__right-char__item
                      span.c-constructor__right-char__item-name Вес
                      span.c-constructor__right-char__item-dot
                      span.c-constructor__right-char__item-final {{weight}}
                    li.c-constructor__right-char__item
                      span.c-constructor__right-char__item-name Цвет
                      span.c-constructor__right-char__item-dot
                      span.c-constructor__right-char__item-final {{val_color}}
                    li.c-constructor__right-char__item
                      span.c-constructor__right-char__item-name Чистота
                      span.c-constructor__right-char__item-dot
                      span.c-constructor__right-char__item-final {{val_clear}}
                  .c-constructor__right-price
                    .c-constructor__right-price__title Стоимость
                    .c-constructor__right-price__num {{$root.numberWithSpaces(val_price)}}
                      template(v-if="currency === 'usd'")
                        | $
                      template(v-else)
                        span.rub i
            .c-form__row
                .c-form__col(v-if="id!='constructor-order'")
                    .c-form__input
                        input(type="text", placeholder="Имя", v-model="name")
                .c-form__col
                    input(type="text", placeholder="Телефон", v-mask="'\+7\(###)###-##-##'" v-model="phone" :class="{'invalid' : $v.phone.$dirty && !$v.phone.required}")
            .c-form__col(v-if="(id!='callback') && (id!='constructor') && (id!='constructor-order')")
                .c-form__input
                  input(type="text", placeholder="Комментарий" v-model="comment")
        button.c-btn.c-btn__constructor(type="submit")
          .c-btn__arr.c-btn__arr-left
            .c-btn__arr-line.c-btn__arr-line_v
            .c-btn__arr-line.c-btn__arr-line_h
          .c-btn__arr.c-btn__arr-right
            .c-btn__arr-line.c-btn__arr-line_v
            .c-btn__arr-line.c-btn__arr-line_h
          span.c-btn__name ОТПРАВИТЬ
        .c-form__agreement Нажимая кнопку "Отправить", Вы соглашаетесь с условиями
            a(href="javascript:void(0)" @click='$modal.show("agreement")') обработки персональных данных
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      form: '',
      name: null,
      phone: null,
      email: null,
      comment: null,
    }
  },
  props: {
    title: {
      default: 'Свяжитесь с нами',
    },
    sub_title: {},
    id: {
      default: 'default',
    },
    val_form: {
      default: '',
    },
    weight: {
      default: 1,
    },
    val_color: {
      default: 0,
    },
    val_clear: {
      default: 0,
    },
    val_price: {
      default: 0,
    },
    val_pic: {
      default: 'form1.png',
    },
    currency: {
      default: 'rub',
    },
  },
  validations: {
    phone: {
      required,
    },
  },
  methods: {
    send(e) {
      e.preventDefault()

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      let formdata = new FormData()
      formdata.append('form', this.title)
      formdata.append('name', this.name)
      formdata.append('phone', this.phone)
      formdata.append('email', this.email)
      formdata.append('comment', this.comment)
      formdata.append('form_diamond', this.val_form)
      formdata.append('weight', this.weight)
      formdata.append('color', this.val_color)
      formdata.append('clear', this.val_clear)
      formdata.append('price', this.val_price)
      axios
        .post('https://onlydiamond.ru/backend/send.php', formdata)
        .then(response => {
          if (response.data.result == 'success') {
            this.$modal.show('success')
            e.target.reset()
          }
        })
        .catch(function() {})
    },
  },
}
</script>
