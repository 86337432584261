import Vue from 'vue'
import Vuex from 'vuex'
import catalog from './modules/catalog'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    catalog,
  },
  strict: process.env.NODE_ENV !== 'production',
  state: {
    phone: '+7 (921) 092-00-04',
    phoneLink: '+79210920004',
    email: 'onlydiamond.spb@gmail.com',
    instargam: 'https://www.instagram.com/onlydiamond.ru/?utm_medium=copy_link',
    vk: 'https://vk.ru/onlydiamond',
    telegram: 'https://t.me/onlydiam',
  },
  getters: {},
  mutations: {},
  actions: {},
})
