import axios from 'axios'
import products from '../data/products.json'
const URL_CURRENCY = `https://www.cbr-xml-daily.ru/daily_json.js`

export default {
  async getCurrencyApi(cb) {
    try {
      const response = await axios.get(URL_CURRENCY)
      cb(response.data.Valute.USD.Value)
    } catch (error) {
      throw new Error(error)
    }
  },
  getProductsApi(cb) {
    setTimeout(() => cb(products), 1)
  },
}
