import Vue from 'vue'
import App from './App.vue'
import { store } from './store'
import router from './router'
import VueMeta from 'vue-meta'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import SvgIcon from '@/components/SvgIcon'
import VModal from 'vue-js-modal'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import Vue2TouchEvents from 'vue2-touch-events'
import VuePageTransition from 'vue-page-transition'

import choses from '@/data/chose.json'
import forms from '@/data/form.json'
import colors from '@/data/color.json'
import clear from '@/data/clear.json'

Vue.config.productionTip = false
Vue.component('svg-icon', SvgIcon)
Vue.component('vue-slider', VueSlider)

Vue.use(Vuelidate)
Vue.use(VModal)
Vue.use(Viewer)
Vue.use(VueMask)
Vue.use(Vue2TouchEvents)
Vue.use(VuePageTransition)
Vue.use(VueMeta)

Vue.use(VueLodash, { name: 'custom', lodash: lodash })

new Vue({
  store,
  router,
  data() {
    return {
      domain: 'https://onlydiamond.ru',
      min_weight: 0.03,
      max_weight: 2.99,
      choses: choses,
      forms: forms,
      colors: colors,
      clears: clear,
      choseTemplateView: '',
      choseModalView: '',
      showMenu: false,
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    choseTemplate(id) {
      this.choseTemplateView = id
      this.choseModalView = id
    },
    hideTemplate() {
      this.choseTemplateView = ''
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    menuClose() {
      this.showMenu = false
    },
    //формат цены
    numberWithSpaces(x) {
      var parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    },
    swipeRightHandler() {
      this.choseTemplateView = ''
    },
  },
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
