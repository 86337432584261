import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'empty',
    },
    choseTemplateView: '',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/constructor/',
    name: 'constructor',
    choseTemplateView: '',
    component: () => import('../views/Constructor.vue'),
  },
  {
    path: '/guide/',
    name: 'guide',
    choseTemplateView: '',
    component: () => import('../views/Guide.vue'),
  },
  {
    path: '/about/',
    name: 'about',
    choseTemplateView: '',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  // {
  //   path: '/catalog/',
  //   name: 'Каталог',
  //   choseTemplateView: '',
  //   component: () => import('../views/Catalog.vue'),
  // },
  {
    path: '/video/',
    name: 'Видео',
    choseTemplateView: '',
    component: () => import('../views/Video.vue'),
  },
  {
    path: '/brillianty/:slug',
    name: 'Бриллианты',
    choseTemplateView: '',
    component: () => import('../views/Brillianty.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
