<template lang="pug">
  #app.c-wrapper
    <Menu :showMenu="this.$root.showMenu"/>
    <Header />
    main.c-main
      <vue-page-transition name="overlay-left">
        <router-view></router-view>
      </vue-page-transition>
    <Navigation/>
    <Modals/>
</template>

<script>
import Header from '@/components/Header'
import Modals from '@/components/Modals'
import Menu from '@/components/Menu'
import Navigation from '@/components/Navigation'

export default {
  name: 'empty-layout',
  components: {
    Menu,
    Header,
    Modals,
    Navigation,
  },
}
</script>

<style>
.overlay-top,
.overlay-right,
.overlay-bottom,
.overlay-left {
  z-index: 10;
}
:root {
  --overlay-bg: linear-gradient(90deg, #fff 0%, #ebefff 100%) !important;
  --transition-duration: 0.35s;
}
</style>
