import catalog from '../../api/catalog'

const DEFAULT_USD = 140

const state = () => ({
  ready: false,
  products: [],
  currencyUsd: DEFAULT_USD,
})

const getters = {}

const actions = {
  getCurrency({ commit }) {
    return new Promise(resolve => {
      catalog.getCurrencyApi(usd => {
        commit('setCurrency', usd)
        resolve()
      })
    })
  },
  getProducts({ commit }) {
    return new Promise(resolve => {
      catalog.getProductsApi(products => {
        commit('setProducts', products)
        commit('setReady', products)
        resolve()
      })
    })
  },
}

const mutations = {
  setCurrency(state, usd) {
    state.currencyUsd = usd
  },
  setProducts(state, products) {
    products.forEach(item => (item.price *= Math.round(state.currencyUsd)))
    state.products = products
  },
  setReady(state) {
    state.ready = true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
